<template>
  <iframe id='pptiframe'  allowfullscreen='true' class="iframes" :src="iframeWinPaht" frameborder="0" ref="iframes" />
  <!-- <iframe
    class="iframes"
    src="http://localhost:8080/#/edit"
    frameborder="0"
    ref="iframes"
    id='pptiframe'
  /> -->
</template>
<script>
import {getVideoUrl} from '@/api/video/videomanage'

export default {

  data() {
    return {
      iframeWinPaht: process.env.VUE_APP_PPT_IDE + "edit",
      pptData: {
        userId: localStorage.getItem("userId"),
        token: localStorage.getItem("pptToken"),
        platform: localStorage.getItem("platform"), //id 1	merchant	可达鸭商户平台 id 2	tms	可达鸭平台
        currentTaskId: "",
        resourceId: "",
        courseId: "",
        courseName: "",
        unitId: "",
        unitName: "",
        sort: 0,
        taskList: [], //编辑时可为空
      },
    };
  },
  mounted() {
    window.addEventListener("message", this.handleMessage, true);
  },
  beforeDestroy() {
    window.removeEventListener("message", this.handleMessage, true);
  },
  created() {
    const currentCourseInfo = JSON.parse(
      localStorage.getItem("currentCourseInfo")
    );
    /**
     *  ci: this.$route.query.ci,
            cn: this.$route.query.cn,
            ui: this.$route.query.ui,
            un: this.$route.query.un,
            ti: this.currentTaskListInfo.id,
            tsourceId: this.currentTaskListInfo.sourceId,
            tsort: this.currentTaskListInfo.sort,
     */
    this.pptData = {
      ...this.pptData,
      currentTaskId: this.$route.query.ti,
      resourceId: this.$route.query.tsourceId,
      courseId: this.$route.query.ci,
      courseName: this.$route.query.cn,
      unitId: this.$route.query.ui,
      unitName: this.$route.query.un,
      sort: this.$route.query.tsort,
      code: '可达鸭编程-'+localStorage.getItem('userName')+'|'+localStorage.getItem('pptToken'),
    };
  },
  methods: {
    handleMessage(e) {
      let pptDom = document.getElementById('pptiframe').contentWindow
      switch (e.data.type) {
        case "setUseWareId":
          this.$emit("setUseWareId", e.data.data.id);
          break;
        case "getData":
          const props = { data: this.pptData, type: "initData" }
          pptDom.postMessage(props, "*");
          break;
        case "getPptVideoInfo":
           getVideoUrl({fileId:e.data.data.id.split('-')[1]}).then(res=>{
              pptDom.postMessage(
            { data: {
              ...res,
              id:e.data.data.id,
              mode:'edit',
              code: '可达鸭编程-'+localStorage.getItem('userName')+'|'+localStorage.getItem('pptToken'),
              }, type: "returnPptVideoInfo" },
            "*"
            )
             
           })
             
          break;
        default:
          break;
      }
    },
  },
};
</script>
<style scoped lang='scss'>
.iframes {
  width: calc(100vw);
  height: calc(100vh);
  overflow: hidden;
}
</style>